$theme-colors: (
    "site-primary": #3f3d56,
    "site-secondary": #e4f1ff,
    "site-light-blue": #8ebdf3,
    "site-dark-blue": #166ee4,
    "site-dark": #000000,
    "site-light-sky": #ebf5ff,
    "site-grey": #cdd0d6,
    "site-lite": #e6e6e6,
    "icon-color": #346cdc,
    "title-text": #3f3d54
);

.site-text-blue {
    color: #166ee4;
}

// @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,600;1,800&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Anek+Tamil:wght@300;400;500;600;700;800&display=swap");
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Poppins:wght@300;400;500;600&display=swap");
@import "./home.scss";
@import "./footer.scss";
@import "./services.scss";
@import "./about.scss";

body {
    background: #ffffff;
    h1,
    h2,
    h3,
    h4 {
        font-family: "Lora", serif;
    }
    h5,
    p {
        font-family: "Poppins", sans-serif;
    }
}

@for $i from 100 through 900 {
    .fw--#{$i} {
        font-weight: $i;
    }
}
@for $size from 5 through 96 {
    .fs--#{$size} {
        font-size: $size/16 + rem !important;
    }
}

@for $size from 5 through 96 {
    @media (min-width: 320.99px) {
        .fs-xs--#{$size} {
            font-size: $size/16 + rem !important;
        }
    }
}

@for $size from 5 through 96 {
    @media (min-width: 425.99px) {
        .fs-sm--#{$size} {
            font-size: $size/16 + rem !important;
        }
    }
}

@for $size from 5 through 96 {
    @media (min-width: 769.99px) {
        .fs-md--#{$size} {
            font-size: $size/16 + rem !important;
        }
    }
}

@for $size from 5 through 96 {
    @media (min-width: 1024.99px) {
        .fs-lg--#{$size} {
            font-size: $size/16 + rem !important;
        }
    }
}

@for $size from 5 through 96 {
    @media (min-width: 1440.99px) {
        .fs-xl--#{$size} {
            font-size: $size/16 + rem;
        }
    }
}

@for $size from 5 through 96 {
    @media (min-width: 1920.99px) {
        .fs-xxl--#{$size} {
            font-size: $size/16 + rem;
        }
    }
}

.active-nav {
    color: #166ee4;
}
.cursor-pointer {
    cursor: pointer;
}
.image-setting {
    position: absolute;
    bottom: 0%;
    width: 50%;
    height: auto;
}
.site-border-radius {
    border: 0px;
    border-radius: 50px;
}
.zindex-1 {
    z-index: 1;
}
.credentialingImg {
    width: 90px;
    height: 100px;
    object-fit: contain;
}
.home {
    &__testimonial {
        &-card {
            border-radius: 49px;
            border: 1px solid #000;
            background: #fff;
            box-shadow: 3px 3px 0px 0px #000;
        }
    }
    &__ourvalues {
        &-card {
            border-radius: 30px;
            border: 1px solid #000;
            background: #e4f1ff;
            box-shadow: 3px 3px 0px 0px #000;
        }
    }
}
.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.resourse {
    .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%232F8FFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    }

    .accordion-button.collapsed::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%232F8FFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    }
    .accordion-flush .accordion-item .accordion-button,
    .accordion-flush .accordion-item .accordion-button.collapsed {
        border-radius: 0;
        color: #2f8fff;
    }
    .icon-wrapper {
        display: inline-block;
        transition: transform 0.3s ease;
    }

    .rotate-up {
        transform: rotate(0deg);
    }

    .rotate-down {
        transform: rotate(-180deg);
    }
}

.effect-card {
    width: 100%;
    height: 300px;
    padding: 1.5rem;
    @media (max-width: 425px) {
        height: 170px;
        padding: 1rem;
    }
    border-radius: 15px;

    background: white;
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: 0.4s ease-out;

    &:hover {
        &:before {
            opacity: 0.7;
        }

        .info {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        background: linear-gradient(180deg, rgba(254, 254, 254, 0) 0%, rgb(0, 0, 1) 50%);
        z-index: 2;
        transition: 0.5s;
        opacity: 0.4;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 15px;
    }

    .info {
        position: relative;
        z-index: 3;
        color: white;
        h5 {
            font-size: 15px;
            line-height: 1.5rem;
            font-weight: 500;
        }
        p {
            font-size: 13px;
            line-height: 1.5rem;
            margin-bottom: 0rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* Limit the number of lines to 2 */
            -webkit-box-orient: vertical;
        }
        span {
            cursor: pointer;
            margin-left: 0.5rem;
            padding-top: 1.55rem;
            font-size: 13px;
            font-weight: 600;
        }
    }
}

.nav-item-hover-header:hover {
    color: #346cdc;
}
