.footer {
    .accordion-flush .accordion-item .accordion-button,
    .accordion-flush .accordion-item .accordion-button.collapsed {
        border-radius: 0;
        background-color: transparent;
        color: white;
    }
    .accordion-button:focus {
        z-index: 3;
        border-color: none;
        outline: none;
        box-shadow: none;
    }
}

.footer-nav {
    color: #e6e6e6;
}
.footer-nav:hover {
    color: #fff;
}
