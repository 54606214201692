.home {
    &-finanical {
        container {
            border-radius: 20em;
        }
    }
}

.slick-dots li button:before {
    font-family: "slick";
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: 0.25;
    color: #6e7075;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #166ee4;
}

.right-text {
    font-size: 30px;
    font-weight: 500;
    // line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    @media (min-width: 426px) {
        font-size: 42px;
        line-height: 54px;
    }
}

.para-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.get-started {
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.get-started-sm {
    width: 150px;
    height: 45px;
}
.services {
    margin: 7rem 0px 7rem 0px;
}

@media screen and (max-width: 786px) {
    .services {
        margin: 2rem 0px 2rem 0px;
    }
}

.services:nth-child(even) {
    flex-direction: row-reverse;
    .service-image {
        margin-right: auto;
    }
}
.services:nth-child(odd) {
    .service-image {
        margin-left: auto;
    }
}
.menu-tip {
    position: absolute;
    z-index: 4;
    right: 40%;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent #fff transparent;

    transform: rotate(0deg);
}

.menu-items {
    width: 440px;
    transform: translateX(-40%);
    position: absolute;
    border-radius: 30px;
    border: 1px;
    background-color: white;
    z-index: 3;
    padding: 2rem;
    -webkit-box-shadow: 0px 2px 41px 0px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0px 2px 41px 0px rgba(0, 0, 0, 0.22);
    box-shadow: 0px 2px 41px 0px rgba(0, 0, 0, 0.22);
    margin-top: 0.75rem;
}

@media screen and (max-width: 786px) {
    .p-sm-0 {
        padding: 0.25rem !important;
    }
    .w-sm-100 {
        width: 100% !important;
    }
}

.why-medical-img {
    background-image: url("../img/doctors-thumbs-up.jpeg");
    background-position: 0px -20px;
    background-repeat: no-repeat;
    background-size: cover;
}

.why-medical {
    height: 520px;
}

.w-lg-25 {
    @media (min-width: 1440px) {
        width: 25% !important;
    }
}
.w-lg-50 {
    @media (min-width: 1440px) {
        width: 50% !important;
    }
}

.why-medical {
    @media (max-width: 769.99px) {
        .CardText,
        h2 {
            font-size: x-large;
        }
        h3 {
            font-size: large;
        }
    }
    @media (max-width: 525.99px) {
        .CardText,
        h2 {
            font-size: x-large;
        }
        h3 {
            font-size: large;
        }
    }
    @media (max-width: 425.99px) {
        .CardText,
        h2 {
            font-size: large;
        }
        h3 {
            font-size: medium;
        }
    }
    @media (max-width: 325.99px) {
        .CardText,
        h2 {
            font-size: medium;
        }
        h3 {
            font-size: small;
        }
    }
}
.finanical {
    height: 430px;
    border-radius: 35px;
    background-color: map-get($theme-colors, "site-light-blue");
    @media (max-width: 1024.99px) {
        // background-color: white !important;
        height: 310px;
        h3 {
            font-size: 21px !important;
        }
    }
    @media (max-width: 425.99px) {
        height: auto !important;
        padding: 1em;
        bac h3 {
            font-size: 1em !important;
        }
    }
    @media (max-width: 375.99px) {
        height: auto !important;
        padding: 1em;
        h3 {
            font-size: 1em !important;
        }
    }
}
.stay-updated {
    &__responsive-setting {
        height: 50% !important;
        @media (max-width: 991.99px) {
            height: 100% !important;
        }
    }
}

.offcanvas {
    max-width: 90vw;
}

.offcanvas-title {
    width: 100%;
}
.offcanvas-body {
    padding-right: 2rem;
    padding-left: 2rem;
}

.side-divider {
    width: 90%;
    margin-top: 21px;
    margin-bottom: 21px;
    border: 1px solid #e4f1ff;
}
.stay-updated {
    @media (max-width: 525.99px) {
        .CardText,
        p,
        h6 {
            font-size: x-small;
        }
    }
    @media (max-width: 377.99px) {
        .CardText,
        p,
        h6,
        small {
            font-size: 8px;
        }
    }
}
