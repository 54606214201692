.at-anz-section {
    p {
        line-height: 1.5em;
        // width: 60% !important;
        font-size: 1.3em;
        // font-family: "Poppins", sans-serif;
    }
}
.talentedstaff-container {
    background-image: url("../img/bacground-ourmission.png"); /* If the image is in the public folder */
    background-size: cover;
    // background-position: center;
    height: auto; /* Example height */
    width: 100%; /* Example width */
    z-index: 9999;
    margin-top: -150px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.our-mission {
    p {
        width: 50%;
        @media (max-width: 886.99px) {
            width: 100% !important;
        }
    }
    Button {
        @media (max-width: 886.99px) {
            width: 100% !important;
        }
    }
}
.slick-prev {
    display: none !important;
}

.slick-next::before {
    color: #d9d9d95d !important;
    font-size: 60px;
    box-shadow: black;
    margin-left: -42px;
    fill: blue;
}
.rounded-lg {
    border-radius: 20px;
    margin-left: 30px !important;
}

.slider-card {
    margin-left: 2px; /* Half of your desired gap */
    margin-right: 2px; /* Half of your desired gap */
}

.lh-3rem {
    line-height: 3.5rem;
}

@media (min-width: 1440px) {
    .w-lg-50 {
        width: 50% !important;
    }
}
@media (min-width: 1024px) {
    .w-md-50 {
        width: 50% !important;
    }
    .talentedstaff-container {
        padding-top: 150px;
        padding-bottom: 50px;
    }
}
// .operation {
//     border: 1px solid gray;
//     padding: 30px;
//     border-radius: 20px;
//     width: 300px;
//     align-self: self-end;
//     @media (max-width: 1024px) {
//         border: 0px !important;
//         padding: 30px;
//         width: 100%;
//         align-self: self-end;
//     }
// }
// .testimonial {
//     .slick-next::before {
//         color: #d9d9d95d !important;
//         font-size: 60px;
//         box-shadow: black;
//         margin-left: -42px;
//     }
// }
