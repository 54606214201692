.line-height {
    line-height: 2rem;
}

.arc {
    background: #8ebdf3;
    margin-top: -1px;
    overflow: hidden;
}

// .arc::after {
//     content: "";
//     display: block;
//     background: white;
//     border-top-left-radius: 100%;
//     border-top-right-radius: 100%;
//     height: 100vh;
//     width: 150vw;
//     margin-left: -25%;
// }
.arc::after {
    content: "";
    display: block;
    background: white;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    border: 150px solid white;
}

.content-section {
    // text-align: center;
    padding: 0px 15px 0px 15px;
    line-height: 2rem;
    letter-spacing: 0.5px;
    img {
        width: 60px;
        max-height: 125px;
        height: auto;
        object-fit: contain;
        @media (min-width: 426px) {
            width: 100px;
            max-height: 125px;
        }
    }
    @media (min-width: 426px) {
        .text-md-left {
            text-align: left !important;
        }
        .align-items-md-start {
            align-items: flex-start;
        }
    }
    @media (min-width: 1440px) {
        .w-lg-50 {
            width: 50% !important;
        }
    }
}
.max-w-850 {
    max-width: 850px;
}

.h-25rem {
    height: 25rem;
}
@media (max-width: 426px) {
    .text-sm-center {
        text-align: center;
    }
    .line-sm-height-2 {
        line-height: 2rem !important;
    }
    .line-sm-height-2-5 {
        line-height: 2.5rem !important;
    }
    // .fs-sm--16 {
    //     text-align: left !important;
    //     font-size: 16px !important;
    //     line-height: 1.5rem !important;
    //     font-weight: 500 !important;
    // }
    .fs-sm--20 {
        font-size: 20px !important;
    }
    .fs-sm-22 {
        font-size: 20px !important;
    }
    .fs-sm-32 {
        font-size: 32px !important;
    }
    .pe-res-img {
        max-width: 150px !important;
        max-height: 60px !important;
        object-fit: contain;
    }
    .img-sm-32 {
        max-width: 32px !important;
        max-height: 32px !important;
        object-fit: contain;
    }
}
.img-sm-32 {
    max-width: 32px !important;
    max-height: 32px !important;
    object-fit: contain;
}

.circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}
.img-80 {
    max-width: 80px !important;
    max-height: 80px !important;
    object-fit: contain;
}

@media (min-width: 768px) {
    .lg-box {
        border: 1px solid #8ebdf3;
        border-radius: 30px;
        padding: 4rem;
        margin-right: 1rem;
    }
    .text-lg-left {
        text-align: left !important;
    }
}

.info-border {
    border: 1px solid #166ee4;
}

.rounded-45 {
    border-radius: 45px;
}
.rounded-30 {
    border-radius: 30px;
}

.p-10 {
    padding: 6rem 1rem 6rem 6rem;
}

.img-position {
    top: -20%;
}

.mask {
    background: #ffffff;
    z-index: 99999;
    position: relative;
    width: 100vw;
    overflow: hidden;
    height: 5rem;
}
.mask-sm {
    background: #fff;
    position: relative;
    overflow: hidden;
    height: 3rem;
    margin-top: -1.5rem;
}

// .service-hero-img {
//     img {
//         width: 60%;
//         height: auto;
//     }
//     @media (min-width: 769px) {
//         img {
//             width: 100%;
//             height: auto;
//         }
//     }
// }

.img-concise {
    div {
        width: 110px;
    }
    img {
        width: auto;
        height: 80px;
    }
    p {
        font-size: 16px;
    }
    @media (min-width: 426px) {
        p {
            font-size: 18px;
        }
        img {
            width: auto;
            height: 135px;
        }
    }
}
.hero-background {
    // min-height: ;
    background: radial-gradient(110% 30% at bottom, transparent 50%, #8ebdf3 51%);
}
.review-card {
    transition: transform 0.3s ease-in-out;
}

.review-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.review-avatar img {
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.info-button {
    background-color: white;
    color: #166ee4;
}

.info-button:hover {
    color: white;
    background-color: #166ee4;
}
